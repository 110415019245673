.assignment-card {
  max-width: 400px;
  width: 400px;
  min-width: 400px;
  background-color: #ffffff;
  transition: all .1s;
  position: relative;
  cursor: pointer;
  .assignment-card-body {
    padding: 1em;
  }
  .assignment-card-header{
    font-size: 1.5em;
  }
  .assignment-card-footer {
    background-color: gray;
    border-bottom-left-radius: .25em;
    border-bottom-right-radius: .25em;
  }

  .assignment-card-square-1{
    opacity: .4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(0% 100%, 0% 35%, 5% 100%);

  }
  .assignment-card-square-2{
    opacity: .7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(0% 100%, 0% 85%, 25% 100%);
  }

  .assignment-card-square-3{
    opacity: .4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 0%, 100% 75%, 91% 0%);
  }
  .assignment-card-square-4{
    opacity: .7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 0%, 100% 20%, 70% 0%);
  }


}
.assignment-card:hover{
  background-color: #f5f5f5;
}

.assignment-card-top-right{
  position: absolute;
  top: 0;
  right: 2px;
}

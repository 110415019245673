.assignment-editor {
  width: 100%;
  background-color: #ffffff;
  transition: all .1s;
  position: relative;
  margin-bottom: 1em;

  .assignment-editor-body {
    padding: 1em;
  }
  .assignment-editor-header{
    font-size: 1.5em;
  }
  .assignment-editor-footer {
    background-color: gray;
    border-bottom-left-radius: .25em;
    border-bottom-right-radius: .25em;
  }

  .assignment-editor-square-1{
    opacity: .4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 0%, 100% 65%, 91% 0%);
  }
  .assignment-editor-square-2{
    opacity: .7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 0%, 100% 20%, 75% 0%);
  }


}
//.assignment-editor:hover{
//  background-color: #f5f5f5;
//}

.assignment-editor-top-right{
  position: absolute;
  top: 0;
  right: 2px;
}


.button-icon-left{
  margin-right: .4em;
}
.button-icon-right{
  margin-left: .4em;
}
.main-button{
  transition: all .1s;

}
.hover-white:hover{
  background-color: #f2f2f2 !important;
  color: #4A4A4A !important;
}

.button-all-caps{

}

button.delete.is-floating {
  position: absolute;
  right: 0;
  top: 0;
}

.menu {
  position: fixed;
  padding: 1em;
  top: 0;
  left: 0;
  z-index: 10000;
  height: 100vh;
  width: 200px;
  overflow-y: auto;


  a {
    color: white;
    padding-top: .1em;
    padding-bottom: .1em;
    transition: all .1s;
  }

  a:hover {
    background-color: #7d7d7d !important;
    color: white;
  }
}

.menu-label {
  color: white;
}

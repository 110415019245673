#brace-editor {
  //border-radius: .25em;
  //box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.hide-ace-cursor{
  .ace_cursor{
    opacity: 0 !important;
  }
  .ace_content{
    cursor: default;
  }
}

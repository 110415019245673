.level-hr {
  margin-top: 0 !important;
}

.level-main {
  margin-bottom: 0.5em !important;
}

.level-header{
  //font-size: 1.8em;
  font-size: 2.8em !important;
}

.level-subheader{
  //margin-left: .2em;
  //line-height: 1.2;
}

@media screen and (max-width: 1200px) {
  .level-header{
    font-size: calc(1200px * 0.035);
  }
}

@media screen and (min-width: 1400px){
  .level-header{
    font-size: calc(1400px * 0.035);
  }
}

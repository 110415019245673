$davy: rgba(89, 89, 89, 1);
$mummy: rgba(128, 143, 133, 1);
$eton: rgba(145, 196, 153, 1);
$darteton: rgba(106, 141, 115, 1);
$eggshell: rgba(244, 253, 217, 1);
$raisin: #222222;
$steel-blue: #3f7cac;
$spring-green: #37FF8B;
$maya-blue: #51D6FF;
$persian-orange: #DF9A57;
$coral: #FC7A57;
$snow: #FCFAF9;
$daffodil: #FFFC31;
$flame: #DE541E;
$fuzzy-wuzzy: #C47168;
$iguana-green: #79B473;
$shiny-shamrock: #5EA855;
$medium-sea-green: #3BA969;
$spanish-green: #109648;

$primary: $steel-blue;
$link: $steel-blue;
$info: $maya-blue;
$success: $medium-sea-green;
$warning: #FED839;
$danger: #db3e38;
$bg-whitish: #F8F9FB;

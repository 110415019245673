@import url('https://use.fontawesome.com/releases/v5.8.2/css/all.css');
@import "Variables";
@import "~bulma/bulma.sass";
@import "buttons";

html, body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;
  height: 100vh;
  background-color: $bg-whitish;
}
html{
  overflow-y: auto;
}
.is-family-serif {
  font-family: serif;
}

.not-rounded {
  border-radius: 0;
}

.tracking-and-caps {
  letter-spacing: 0.08em;
  text-transform: uppercase;
}

.bigger-font {
  font-size: 1.1em;
}

button {
  transition: background-color .05s;
}

.is-full-screen {
  height: 100%;
}



hr {
  background-color: #ebebeb;
}
.no-nav-style{
  color: inherit;
}
textarea {
  @extend code;
}

pre code {
  font-size: 1rem;
}

.textarea {

}


.hr-text {
  line-height: 1em;
  position: relative;
  outline: 0;
  border: 0;
  color: black;
  text-align: center;
  height: 1.5em;
  opacity: .5;
  background-color: transparent;

  &:before {
    content: '';
    // use the linear-gradient for the fading effect
    // use a solid background color for a solid bar
    background: linear-gradient(to right, transparent, #818078, transparent);
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
  }

  &:after {
    content: attr(data-content);
    position: relative;
    display: inline-block;
    padding: 0 .5em;
    line-height: 1.5em;
    // this is really the only tricky part, you need to specify the background color of the container element...
    color: #818078;
    background-color: #f5f5f3;
  }
}

pre {
  background-color: inherit;
  padding: 0;
}

progress {
  margin-bottom: .2em !important;
}
.position-relative{
  position: relative;
}

.text-overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.columns {
  margin: 0;
}

.no-padding-top {
  padding: 0;
}

.gradient-primary {
  background: rgb(63, 124, 172);
  background: linear-gradient(290deg, rgb(103, 141, 171) 0%, rgba(63, 124, 172, 1) 44%, rgba(18, 67, 106, 1) 100%);
}

.sidenav {
  min-width: 200px;
  max-width: 210px;
  padding-left: 24px;
  background-color: $grey-dark;
  transition: all .1s;
  color: white !important;
}

//.sidenav:hover{
//  background-color: #f8f8f8;
//}

.relative {
  position: relative !important;
}

span.delete {
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 100000;
}

span.delete-reverse {
  background-color: $danger;
  transition: background-color .1s;
}

span.delete-reverse:hover {
  background-color: #9c3d39;
}

.overflow-y-scroll {
  overflow-y: scroll;
  overflow-scrolling: touch;
}
.card-0 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
}

.card-1:hover {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-2 {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.card-3 {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
}

.card-4 {
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}

.card-5 {
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
}

div.is-rounded {
  border-radius: .25em;
}

code.is-rounded {
  border-radius: .25em;
  padding: 1em;
  user-select: none;
}

pre {
  @extend .card-0;
  border-radius: .25em;
}



.module-view-poly1{
  opacity: .4;
  position: fixed;
  width: 200px;
  height: 200px;
  right: 0;
  bottom: 0;
  clip-path: polygon(100% 100%, 100% 20%, 80% 100%);
}
.module-view-poly2{
  opacity: .7;
  position: fixed;
  width: 200px;
  height: 200px;
  right: 0;
  bottom: 0;
  clip-path: polygon(100% 100%, 100% 75%, 30% 100%);

}

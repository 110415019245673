.module-card {
  max-width: 400px;
  min-width: 400px;
  //background-color: #f5f5f5;
  background-color: #ffffff;
  transition: all .1s;
  position: relative;
  cursor: pointer;

  .module-title-container{
    margin-bottom: 1em;
    .module-card-title{
      font-size: 1.75em;
    }
  }


  .module-card-body {
    padding: 1em;
  }
  .module-card-footer {
    background-color: gray;
  }
  .module-card-square-1{
    opacity: .4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 100%, 100% 30%, 90% 100%);
  }
  .module-card-square-2{
    opacity: .7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 100%, 100% 75%, 75% 100%);
  }


}
.module-card:hover{
  background-color: #f5f5f5;
}

.module-card-top-right{
  position: absolute;
  top: 0;
  right: 2px;
}


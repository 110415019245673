@import "../../Variables";

.notification-container{
  display: inline-block;
  align-items: center;
  height: 40px;
  //border-radius: 5px;
  //padding-top: 8px;

  .notification-icon{
    padding-right: 8px;
    color: white;
    padding-left: 8px;
    padding-top: 8px;
  }
  .notification-text{
    padding-top: 8px;
    line-height: 1.43;
    padding-right: 12px;
    color: white;
  }
}

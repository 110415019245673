.class-view-poly1{
  opacity: .4;
  position: fixed;
  width: 200px;
  height: 200px;
  right: 0;
  top: 0;
  clip-path: polygon(100% 0%, 100% 80%, 85% 0%);
}
.class-view-poly2{
  opacity: .7;
  position: fixed;
  width: 200px;
  height: 200px;
  right: 0;
  top: 0;
  clip-path: polygon(100% 0%, 100% 20%, 40% 0%);
}

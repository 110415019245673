.buttons {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .button {
    margin-bottom: 0.5rem;
    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.5rem; } }
  .no-nav-style {
    //margin-bottom: 0.5rem
    &:not(:last-child):not(.is-fullwidth) {
      margin-right: 0.5rem; } }
  &:last-child {
    margin-bottom: -0.5rem; }
  &:not(:last-child) {
    margin-bottom: 1rem; }
  // Sizes
  &.are-small {
    .button:not(.is-normal):not(.is-medium):not(.is-large) {
      @include button-small; } }
  &.are-medium {
    .button:not(.is-small):not(.is-normal):not(.is-large) {
      @include button-medium; } }
  &.are-large {
    .button:not(.is-small):not(.is-normal):not(.is-medium) {
      @include button-large; } }
  &.has-addons {
    .button {
      &:not(:first-child) {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0; }
      &:not(:last-child) {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
        margin-right: -1px; }
      &:last-child {
        margin-right: 0; }
      &:hover,
      &.is-hovered {
        z-index: 2; }
      &:focus,
      &.is-focused,
      &:active,
      &.is-active,
      &.is-selected {
        z-index: 3;
        &:hover {
          z-index: 4; } }
      &.is-expanded {
        flex-grow: 1;
        flex-shrink: 1; } } }
  &.is-centered {
    justify-content: center;
    &:not(.has-addons) {
      .button:not(.is-fullwidth) {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } } }
  &.is-right {
    justify-content: flex-end;
    &:not(.has-addons) {
      .button:not(.is-fullwidth) {
        margin-left: 0.25rem;
        margin-right: 0.25rem; } } } }

.type-error-text{
  text-decoration: underline;
  cursor: pointer;
}
.type-error-container{
  padding: 1em;
  min-width: 300px;
  margin-top: 1em;
  color: white;
}

.practice-main {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.practice-hr {
  margin-top: 1em;
  margin-bottom: 1em;
}

.practice-left {
  margin-right: 1em;
}

.practice-right {
  margin-left: 1em;
}
.console{
  position: absolute;
  bottom: 0;
  background-color: #313131;
  color: white;
  width: 100%;
  line-height: 1.4;
  padding: 5px;
  padding-left: 10px;
}

.type-title{
  font-size: 3.5em !important;
  padding-top: .25em;

}

.type-subtitle{
  font-size: 1.75em !important;

}
.practice-header{
  margin-bottom: 2em;
}
.practice-width{
  //width: 100% !important;
}
.code-containers{
  width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.animated-type{
  display: inline-block;
}
.type-code-container{
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
}

.home-hero {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 1.5em;
  padding-right: 1.5em;
  margin-bottom: 0 !important;

  .home-title {
    font-size: 10em;
    font-weight: 400;
    margin-bottom: .3em;
  }

  .home-typing {
    height: 1em;
    font-size: 1.5em;
  }


  @media only screen and (max-width: 1200px)  {
    .home-title{
      font-size: 7em;
    }
    .home-typing{
      font-size: 1.25em;

    }

  }
  @media only screen and (max-width: 800px)  {
    .home-title{
      font-size: 5em;
    }
    .home-typing{
      font-size: 1em;
    }

  }




  strong, span, h1{
    color: white;
  }

  .home-down-arrow {
    position: absolute;
    background-color: #f0f0f0;
    width: 100px;
    height: 50px;
    bottom: 1.5vh;
    clip-path: polygon(0% 0%, 100% 0%, 50% 90%);
    transition: .1s cubic-bezier(.25, .8, .25, 1);
    cursor: pointer;
  }

  .home-down-arrow:hover {
    //noinspection CssInvalidPropertyValue
    background-color: white;
    transform: translateY(4px);
  }
}

.home-content{
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}
.home-container {
  margin-top: 1em;
  margin-bottom: 2em;
}

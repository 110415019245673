.class-card {
  max-width: 400px;
  width: 400px;
  min-width: 400px;
  background-color: #ffffff;
  transition: all .1s;
  position: relative;
  cursor: pointer;
  .class-card-body {
    padding: 1em;
  }
  .class-card-header{
    font-size: 1.75em;
  }
  .class-card-footer {
    background-color: gray;
    border-bottom-left-radius: .25em;
    border-bottom-right-radius: .25em;
  }

  .class-card-square-1{
    opacity: .4;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 0%, 100% 65%, 91% 0%);
  }
  .class-card-square-2{
    opacity: .7;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    clip-path: polygon(100% 0%, 100% 20%, 75% 0%);
  }


}
.class-card:hover{
  background-color: #f5f5f5;
}

.class-card-top-right{
  position: absolute;
  top: 0;
  right: 2px;
}
